.about-container {
	display: flex;
	flex-direction: column;
	height: 100%;
	padding-top: 150px;
}

.about-main {
	display: flex;
}

.about-left-side {
}

.about-title {
	width: 100% !important;
}

.about-subtitle {
	width: 100% !important;
}

.about-right-side {
	display: flex;
	flex-direction: column;
}

.about-image-container {
	width: 370px;
}

.about-image-wrapper {
	overflow: hidden;
	border-radius: 10%;
	margin-top: 30px;
}

.about-image-wrapper img {
	display: block;
	width: 100%;
}


@media (max-width: 1024px) {
	.about-main {
		flex-direction: column;
	}

	.about-subtitle {
		padding-top: 20px !important;
	}

	.about-right-side {
		flex-basis: 100%;
		order: 2;
	}

	.about-left-side {
		flex-basis: 100%;
		order: 1;
	}

	.about-image-container {
		padding-bottom: 40px;
	}
}

@media (max-width: 600px) {
	.about-container {
		padding-top: 60px;
	}

	.about-image-container {
		width: 80%;
	}
}
