.all-projects-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0;
    padding-top: 150px;
}

.all-projects-project {
    box-sizing: border-box;
    padding: 10px;
}

@media (max-width: 600px) {
    .all-projects-project {
    }
}
