@import "../../../data/globalStyles.css";

.project-container:hover {
    background: #fafafa;
    opacity: 1;
    transition: background-color 0.3s ease-in-out;
}

.project-container:hover .project-link {
    color: var(--link-color);
    transition: color 0.3s ease-in-out;
}

.project {
    border-radius: 20px;
    opacity: 0.8;
    height: 100%;
}

.project-container a {
    text-decoration: none;
}

.project-container {
    display: flex;
    border-radius: 20px;
    flex-direction: column;
    padding: 30px;
}

.project-main {
    display: flex;
}

.project-left-side {
}

.project-right-side {
    display: flex;
    flex-direction: column;
}

.project-image-container {
    width: 300px;
}

.project-image-wrapper {
    overflow: hidden;
    border-radius: 10%;
    margin: 30px;
}

.project-image-wrapper img {
    display: block;
    width: 100%;
}



.project-logo {
    width: 30px;
}

.project-logo img {
    width: 100%;
}



.project-title {
    font-family: var(--secondary-font);
    color: var(--primary-color);
    font-size: 20px;
    padding-top: 15px;
    margin-bottom: 1em;
    font-weight: 700;
}

.project-description {
    color: var(--secondary-color);
    font-size: 17px;
}

.project-stack {
    padding-top: 10px;
    color: var(--primary-color);
    font-style: italic;
    font-size: 15px;
}

.project-link {
    display: flex;
    color: var(--secondary-color);
    font-size: 13px;
    padding-top: 20px;
}

.project-link-icon {
    padding-left: 5px;
    font-size: 15px;
}

.project-link-text {
    padding-left: 10px;
    font-weight: 700;
}

@media (max-width: 1024px) {
    .project-main {
        flex-direction: column;
    }

    .project-right-side {
        flex-basis: 100%;
        order: 2;
    }

    .project-left-side {
        flex-basis: 100%;
        order: 1;
    }

    .project-image-container {
        padding-bottom: 40px;
    }
}

@media (max-width: 600px) {
    .project-container {
        padding-top: 60px;
    }

    .project-image-container {
        width: 80%;
    }
}
