@import "../../../data/globalStyles.css";

.contact-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0;
    padding-top: 50px;
    padding-bottom: 50px;
}

.contact-main {
    display: flex;
}

.contact-left-side {
    flex-basis: 900px;
}

.contact-title {
    width: 100% !important;
}

.contact-subtitle {
    width: 100% !important;
}


@media (max-width: 1024px) {
    .contact-main {
        flex-direction: column;
    }

    .contact-subtitle {
        padding-top: 20px !important;
    }

    .contact-left-side {
        flex-basis: 100%;
        order: 2;
    }
}

@media (max-width: 600px) {
    .contact-container {
        padding-top: 60px;
    }
}

